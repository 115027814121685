import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../assets//images/terms-of-service.jpg'

const TermsOfService = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Terms of Service" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Terms of Service" 
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <img src={termsImg} alt="tt" />
                                <p><i>This Privacy Policy was last updated on July 1st, 2022.</i></p>
                                <p>Welcome to our website. By using our site, you are agreeing to comply with and be bound by the following terms of use. Please review the following terms carefully. If you do not agree to these terms, you should not use this site. Using this website means you agree to the terms and conditions set forth in this Terms of Use Agreement with respect to our site.</p>

                                <h3>Data Privacy</h3>
                                <p>We agree to treat student data as confidential and not to share it with third parties other than as described in individual agreements with educational institutions.</p>

                                <h3>Copyright</h3>
                                <p>The content  and other matters related to the site are protected under applicable copyrights, trademarks and other proprietary (including but not limited to intellectual property) rights. The copying, redistribution, use or publication by you of any such matters or any part of the site, is strictly prohibited. You do not acquire ownership rights to any article, document or other materials viewed through the site. The posting of information or materials on the Site does not constitute a waiver of any right in such information and materials.</p>

                                <h3>Third Party Content</h3>
                                <p>Third party content may appear on the site or may be accessible via links from the site. We are not responsible for and assume no liability for any third party content. You understand that the information and opinions in the third party content represents solely the thoughts of the author and are neither endorsed by nor does it necessarily reflect our belief.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="#">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;